import React from "react"
import { graphql } from "gatsby"
import {Col, Container, Row} from "react-bootstrap";
import {ResponseForm} from "gatsby-theme-nieuwbouw/src/components/response/Form";
import {Bouwtype, Project} from "gatsby-theme-nieuwbouw/src/graphqlTypes";
import DNA from "../components/DNA";
import Realisatie from "../components/Realisatie";
import Page from "gatsby-theme-nieuwbouw/src/components/Page";
import {IPage} from "../types";
import {GatsbyImage} from "gatsby-plugin-image";

interface Props {
    data: PageQueryData
}
export default ({ data }: Props) => {
    const {objectcode, afdelingscode, referral} = data.site.siteMetadata
    const bouwtypes = [data.a, data.b, data.c].map(type => {
        return {
            label: `Type ${type.algemeen.omschrijving}`,
            objectcode: type.diversen.objectcode as string,
        }
    })
    return (
        <Page pageTitle={data.file.childMarkdownRemark.frontmatter.title} pageDescription={data.file.childMarkdownRemark.frontmatter.description}>
            {data.project.hoofdfoto &&
                <GatsbyImage alt={'ai'} image={data.project.hoofdfoto.localFile.childImageSharp.gatsbyImageData}/>
            }
            <Container
                fluid={'lg'}
                className={'text-center text-md-left'}
            >
                <div className={'my-5'}>
            <span className={'title text-uppercase font-weight-bold'}>
                Wij helpen u graag
            </span>
                    <h2>
                        Contact
                    </h2>
                </div>
                <Row>
                    <Col md={7} className={'my-5'}>
                        <ResponseForm objectcode={objectcode} afdelingscode={afdelingscode} referral={referral} objects={bouwtypes} />
                    </Col>
                    <Col md={5}>
                        <div className={"px-5 my-5"}>
                            <div className={"mb-5"}>
                                <DNA />
                            </div>
                            <Realisatie />
                        </div>
                    </Col>
                </Row>
            </Container>
        </Page>
    );
}

interface PageQueryData {
    file: {
        childMarkdownRemark: IPage
    }
    site: {
        siteMetadata: {
            objectcode: string
            afdelingscode: string
            referral: string
        }
    }
    project: Project
    a: Bouwtype
    b: Bouwtype
    c: Bouwtype
}

export const pageQuery = graphql`
    query {
        file(name: {eq: "contact"}) {
            childMarkdownRemark {
                ...Page
            }
        }
        project {
            hoofdfoto {
                localFile {
                    childImageSharp {
                        gatsbyImageData(aspectRatio: 3)
                    }
                }
            }
        }
        site {
            siteMetadata {
                objectcode
                afdelingscode
                referral
            }
        }
        a: bouwtype(diversen: {objectcode: {eq: "O100087"}}) {
            ...BouwtypeCard
        }
        b: bouwtype(diversen: {objectcode: {eq: "O100088"}}) {
            ...BouwtypeCard
        }
        c: bouwtype(diversen: {objectcode: {eq: "O100089"}}) {
            ...BouwtypeCard
        }
    }
`

